import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchLoadflowFlowsStrAction } from 'modules/options';
import { loadflowFlowsStrSelectorFactory } from 'modules/options/selectors';
import { FormInput, FormInputProps } from '@utiligize/shared/components';

interface Props extends FormInputProps {
  value: Calculations.NewExecution['flow_type'];
  environment: Layouts.Filters['environment'] | null;
}

const FlowTypesInput: React.FC<Props> = ({ name, value, environment, onChange, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const loadflowFlowsStrSelector = useMemo(() => loadflowFlowsStrSelectorFactory(`${environment}`), [environment]);
  const loadflowFlowsStr = useSelector(loadflowFlowsStrSelector);

  useEffect(() => {
    if (!environment || loadflowFlowsStr) return;
    setIsLoading(true);
    dispatch(fetchLoadflowFlowsStrAction(environment)).finally(() => setIsLoading(false));
  }, [environment, loadflowFlowsStr, dispatch]);

  useEffect(() => {
    onChange?.({ target: { name, value: loadflowFlowsStr } } as React.ChangeEvent<HTMLInputElement>);
  }, [loadflowFlowsStr, name, onChange]);

  return (
    <FormInput {...props} disabled={isLoading} name={name} value={value} labelKey="Flow type" onChange={onChange} />
  );
};
export default FlowTypesInput;

import React, { useMemo } from 'react';
import { FormReactSelect, FormReactSelectProps } from '@utiligize/shared/components';
import { DataStatus } from 'constants/index';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Type.DataStatus | null;
}

const SelectStatus: React.FC<Props> = ({ value, ...props }) => {
  const { options, selectValue } = useMemo(
    () =>
      Object.values(DataStatus).reduce(
        (acc: any, status: Type.DataStatus) => {
          const item = { value: status, label: status };
          if (value === status) acc.selectValue = item;
          acc.options.push(item);
          return acc;
        },
        { options: [], selectValue: null }
      ),
    [value]
  );

  return <FormReactSelect {...props} value={selectValue} options={options} isSearchable={false} />;
};

export default SelectStatus;

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { applyPortfolioFiltersAction, fetchPortfolioOptionsAction } from 'modules/options';
import { portfolioOptionsSelectorFactory } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from '@utiligize/shared/components';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['portfolio'];
  tenant: Layouts.Filters['tenant'];
}

const SelectPortfolio: React.FC<Props> = ({ tenant, value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const portfolioOptionsSelector = useMemo(() => portfolioOptionsSelectorFactory(tenant), [tenant]);
  const options = useSelector(portfolioOptionsSelector);

  useEffect(() => {
    if (!tenant) return;
    if (options) {
      dispatch(applyPortfolioFiltersAction(options));
    } else {
      setIsLoading(true);
      dispatch(fetchPortfolioOptionsAction(tenant)).finally(() => setIsLoading(false));
    }
  }, [tenant, options, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        if (value === option.value) acc.selectValues.push(option);
        acc.selectOptions.push(option);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
  }, [value, options]);

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={!tenant || isLoading}
      labelKey=""
      mutedTextLabelKey="Portfolio"
      value={selectValues}
      options={selectOptions}
      {...props}
      isSearchable={false}
    />
  );
};

export default SelectPortfolio;

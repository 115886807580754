import { createSelector } from 'reselect';

// ------------------------------------
// Selectors
// ------------------------------------
const rootSelector = (state: State.Root) => state.options;

export const tenantsOptionsSelector = createSelector(rootSelector, options => options.tenantsOptions);

export const workflowTenantsOptionsHashSelector = createSelector(
  rootSelector,
  options => options.workflowTenantsOptionsHash
);

export const workflowTenantsOptionsSelectorFactory = (key: string) =>
  createSelector(workflowTenantsOptionsHashSelector, hash => hash[key]);

export const tagsOptionsSelector = createSelector(rootSelector, options => options.tagsOptions);

export const testsOptionsSelector = createSelector(rootSelector, options => options.testsOptions);

export const portfolioOptionsHashSelector = createSelector(rootSelector, options => options.portfolioOptionsHash);

export const portfolioOptionsSelectorFactory = (tenant: Layouts.Filters['tenant']) =>
  createSelector(portfolioOptionsHashSelector, hash => hash[String(tenant)]);

export const scenarioOptionsHashSelector = createSelector(rootSelector, options => options.scenarioOptionsHash);

export const scenarioOptionsSelectorFactory = (key: string) =>
  createSelector(scenarioOptionsHashSelector, hash => hash[key]);

export const loadflowFlowOptionsHashSelector = createSelector(rootSelector, options => options.loadflowFlowOptionsHash);

export const loadflowFlowOptionsSelectorFactory = (env: Layouts.Filters['environment'] | null) =>
  createSelector(loadflowFlowOptionsHashSelector, hash => hash[env || '']);

export const loadflowEnvironmentOptionsSelector = createSelector(
  rootSelector,
  options => options.loadflowEnvironmentOptions
);

export const loadflowStepsStrHashSelector = createSelector(rootSelector, options => options.loadflowStepsStrHash);

export const loadflowStepsStrSelectorFactory = (key: string) =>
  createSelector(loadflowStepsStrHashSelector, hash => hash?.[key] || null);

export const loadflowFlowsStrHashSelector = createSelector(rootSelector, options => options.loadflowFlowsStrHash);

export const loadflowFlowsStrSelectorFactory = (key: string) =>
  createSelector(loadflowFlowsStrHashSelector, hash => hash?.[key] || null);

export const loadflowPortfolioOptionsHashSelector = createSelector(
  rootSelector,
  options => options.loadflowPortfolioOptionsHash
);

export const loadflowPortfolioOptionsSelectorFactory = (key: string) =>
  createSelector(loadflowPortfolioOptionsHashSelector, hash => hash?.[key] || null);

export const loadflowScenarioOptionsHashSelector = createSelector(
  rootSelector,
  options => options.loadflowScenarioOptionsHash
);

export const loadflowScenarioOptionsSelectorFactory = (key: string) =>
  createSelector(loadflowScenarioOptionsHashSelector, hash => hash?.[key] || null);

export const loadflowInvestmentScenarioOptionsHashSelector = createSelector(
  rootSelector,
  options => options.loadflowInvestmentScenarioOptionsHash
);

export const loadflowInvestmentScenarioOptionsSelectorFactory = (key: string) =>
  createSelector(loadflowInvestmentScenarioOptionsHashSelector, hash => hash?.[key] || null);

export const dataHistoryTestOptionsHashSelector = createSelector(
  rootSelector,
  options => options.dataHistoryTestOptionsHash
);

export const dataHistoryTestOptionsSelectorFactory = (key: string) =>
  createSelector(dataHistoryTestOptionsHashSelector, hash => hash?.[key] || null);

export const meterDataIngestionTenantsOptionsSelector = createSelector(
  rootSelector,
  options => options.meterDataIngestionTenantsOptions
);

import Select, {
  CSSObjectWithLabel,
  InputActionMeta,
  SelectComponentsConfig,
  components,
  MultiValueRemoveProps,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import React from 'react';
import { Form } from 'react-bootstrap';
import theme from '@utiligize/shared/theme';

export type { InputActionMeta };
export interface FormReactSelectProps {
  className?: string;
  labelClassName?: string;
  label?: React.ReactElement | string;
  labelKey?: string;
  mutedTextLabelKey?: string;
  placeholder?: string;
  placeholderKey?: string;
  errorKey?: string;
  name?: string;
  value: { value: number | string; label: string | JSX.Element } | { value: number | string; label: string }[] | null;
  inputValue?: string;
  options: any[];
  isCreatableSelect?: boolean;
  blurInputOnSelect?: false;
  isMulti?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  menuIsOpen?: boolean;
  isSearchable?: boolean;
  components?: SelectComponentsConfig<{}, boolean, any>;
  menuPortalTarget?: HTMLElement;
  onChange?: (value: any, actionMeta: Type.SelectActionMetaBase) => void;
  onInputChange?: (value: string, actionMeta: InputActionMeta) => void;
  onFocus?: (event: React.SyntheticEvent) => void;
  onBlur?: (event: React.SyntheticEvent) => void;
  onKeyDown?: (event: React.SyntheticEvent) => void;
  noOptionsMessage?: () => string;
  getIntl?: (localeKey: string, options?: {}) => string;
  disableMultiValueRemoveForLastOption?: true;
}

const FormReactSelect: React.FC<FormReactSelectProps> = ({
  className = '',
  labelClassName = '',
  label,
  labelKey = '',
  mutedTextLabelKey = '',
  placeholder,
  placeholderKey = 'Select',
  errorKey = '',
  isCreatableSelect,
  getIntl = str => str,
  disableMultiValueRemoveForLastOption,
  ...props
}) => {
  const selectStyles = {
    container: (base: any) => ({ ...base, fontSize: '0.90rem' }),
    control: (base: any) => ({
      ...base,
      minHeight: 32,
      borderRadius: 8,
      borderColor: '#e1e6ec',
    }),
    valueContainer: (base: any) => ({ ...base, padding: '0 0 0 8px' }),
    dropdownIndicator: (base: any) => ({ ...base, padding: '5px' }),
    clearIndicator: (base: any) => ({ ...base, padding: '5px 0 5px 5px' }),
    indicatorSeparator: () => ({ display: 'none' }),
    input: (base: any) => ({ ...base, margin: 0 }),
    option: (base: any) => ({ ...base, padding: '6px 12px' }),
    multiValue: (base: any) => ({
      ...base,
      backgroundColor: theme.colors.purple50,
      borderRadius: '8px',
      padding: '0 8px',
    }),
    multiValueLabel: (base: any) => ({
      ...base,
      color: theme.colors.purple800,
      fontWeight: '500',
      paddingLeft: '3px',
      padding: '3px 2px 1px 2px',
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: theme.colors.purple400,
      paddingRight: 'unset',
      paddingLeft: '3px',
      transition: 'all 0.3s ease',
      ':hover': {
        color: theme.colors.purple800,
        transform: 'scale(1.2)',
      },
    }),
  };

  // Custom MultiValueRemove component to hide the remove icon for the last selected option
  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    if (Array.isArray(props.selectProps.value) && props.selectProps.value.length === 1) {
      return null; // Don't render the remove icon for the last option
    }
    return <components.MultiValueRemove {...props} />;
  };

  const selectProps = {
    noOptionsMessage: () => getIntl('No options'),
    placeholder: placeholder || getIntl(placeholderKey),
    components: disableMultiValueRemoveForLastOption && { MultiValueRemove },
    blurInputOnSelect: !props.isMulti,
    ...props,
    className: `is-invalid ${className}`,
    styles: {
      menu: (base: CSSObjectWithLabel) => ({
        ...base,
        zIndex: 3,
        marginTop: 1,
        borderRadius: 8,
      }),
      ...(props.menuPortalTarget ? { menuPortal: (base: CSSObjectWithLabel) => ({ ...base, zIndex: 10001 }) } : {}),
      ...selectStyles,
    },
  };

  const field_name = mutedTextLabelKey.replaceAll(' ', '_').toLowerCase();
  let data_marker_value_label = null;
  if (Array.isArray(props.value)) {
    data_marker_value_label = props.value[0] && props.value[0].label;
  } else {
    data_marker_value_label = props.value && props.value.label;
  }
  return (
    <>
      {(label || labelKey) && (
        <Form.Label data-marker-label={data_marker_value_label} className={labelClassName}>
          {label || getIntl(labelKey)}
        </Form.Label>
      )}
      {!isCreatableSelect ? (
        <Select id={field_name && `select_block_${field_name}`} {...selectProps} />
      ) : (
        <CreatableSelect {...selectProps} />
      )}
      {Boolean(errorKey) && <Form.Control.Feedback type="invalid">{getIntl(errorKey || '')}</Form.Control.Feedback>}
      {mutedTextLabelKey && <Form.Text className="text-muted">{getIntl(mutedTextLabelKey)}</Form.Text>}
    </>
  );
};

export default FormReactSelect;

import queryString from 'query-string';
import { processToken } from 'components/SetupAxios';
import Keycloak from 'keycloak-js';
import { getStorageItem, setStorageItem } from 'utils';
import { Routes, StorageKeys, PaginationType } from 'constants/index';

const _kc = new Keycloak({
  realm: process.env.REACT_APP_OIDC_REALM,
  url: process.env.REACT_APP_OIDC_URL,
  clientId: process.env.REACT_APP_OIDC_CLIENT_ID,
});

const initKeycloak = onAuthenticatedCallback => {
  const { pathname, hash, search } = window.location;
  if (Routes.Root !== pathname && Routes.Callback !== pathname) {
    // { portfolio_id: '4', scenario_id: '58', tenant: 'Tenant NORD ENERGI NET A/S' }
    const { tenant, portfolio_id, scenario_id, theme, theme_group, portfolio, scenario, test } =
      queryString.parse(search);

    const addInternalAdminDeepLinks = ({ tenant, portfolio, scenario, test }) => {
      if (!portfolio && !scenario && !test) return {};
      const pagination = getStorageItem(StorageKeys.PAGINATION);
      const dataHistoryPagination = pagination?.[PaginationType.ADMIN_DATA_HISTORY];
      return {
        [StorageKeys.PAGINATION]: {
          ...pagination,
          [PaginationType.ADMIN_DATA_HISTORY]: {
            ...dataHistoryPagination,
            filters: {
              ...dataHistoryPagination?.filters,
              tenant: tenant || dataHistoryPagination?.filters.tenant,
              portfolio: portfolio || dataHistoryPagination?.filters.portfolio,
              scenario: scenario || dataHistoryPagination?.filters.scenario,
              test: test || dataHistoryPagination?.filters.test,
            },
          },
        },
      };
    };

    // Set last route before callback and return to it once auth check completed
    setStorageItem({
      [StorageKeys.LAST_ROUTE]: `${pathname}${hash}`,
      ...(tenant ? { [StorageKeys.SELECTED_TENANT]: tenant } : {}),
      ...(portfolio_id ? { [StorageKeys.PORTFOLIO_ID]: Number(portfolio_id) } : {}),
      ...(scenario_id ? { [StorageKeys.SCENARIO_ID]: Number(scenario_id) } : {}),
      ...(theme && theme_group
        ? {
            [StorageKeys.MAP_CACHE]: {
              ...(getStorageItem(StorageKeys.MAP_CACHE) || {}),
              theme,
              themeGroup: theme_group,
            },
          }
        : {}),
      // Internal admin
      ...addInternalAdminDeepLinks({ tenant, portfolio, scenario, test }),
    });
  }
  _kc
    .init({
      onLoad: 'login-required',
      pkceMethod: 'S256',
      redirectUri: window.location.origin + Routes.Callback,
      checkLoginIframe:
        window.location.origin !== 'http://localhost:3000' && window.location.origin !== 'http://localhost:3001',
    })
    .then(authenticated => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    .catch(console.error);

  _kc.onTokenExpired = () => {
    _kc.updateToken().then(processToken).catch(doLogin);
  };

  _kc.onReady = () => {
    processToken();
  };
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;
const getParsedToken = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = successCallback => _kc.updateToken(60).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = roles => roles.some(role => _kc.hasRealmRole(role));

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  getParsedToken,
};

export default UserService;

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchScenarioOptionsAction } from 'modules/options';
import { scenarioOptionsSelectorFactory } from 'modules/options/selectors';
import { FormReactSelect, FormReactSelectProps } from '@utiligize/shared/components';

interface Props extends Omit<FormReactSelectProps, 'value' | 'options'> {
  value: Layouts.Filters['portfolio'];
  tenant: Layouts.Filters['tenant'];
  portfolio: Layouts.Filters['portfolio'];
}

const SelectScenario: React.FC<Props> = ({ tenant, portfolio, value, ...props }) => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const key = `${tenant}_${portfolio}`;
  const scenarioOptionsSelector = useMemo(() => scenarioOptionsSelectorFactory(key), [key]);
  const options = useSelector(scenarioOptionsSelector);

  useEffect(() => {
    if (!tenant || !portfolio || options) return;
    setIsLoading(true);
    dispatch(fetchScenarioOptionsAction({ tenant, portfolio, key })).finally(() => setIsLoading(false));
  }, [tenant, portfolio, key, options, dispatch]);

  const { selectValues, selectOptions } = useMemo(() => {
    if (!options) return { selectValues: [], selectOptions: [] };

    return options.reduce(
      (acc: { selectValues: Type.SelectOption[]; selectOptions: Type.SelectOption[] }, option) => {
        if (value === option.value) acc.selectValues.push(option);
        acc.selectOptions.push(option);
        return acc;
      },
      { selectValues: [], selectOptions: [] }
    );
  }, [value, options]);

  return (
    <FormReactSelect
      isLoading={isLoading}
      isDisabled={!tenant || !portfolio || isLoading}
      labelKey=""
      mutedTextLabelKey="Scenario"
      value={selectValues}
      options={selectOptions}
      {...props}
      isSearchable={false}
    />
  );
};

export default SelectScenario;

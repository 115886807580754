import Highcharts from 'highcharts';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { meterDataIngestionItemsSelector } from 'modules/uptime/selectors';
import Chart, { getBaseOptions } from '@utiligize/shared/components/Chart';
import { dateFormat } from '@utiligize/shared/utils';

const fillMissingDates = (items: Uptime.MeterDataIngestionItem[]) => {
  const sortedItems = [...items].sort(
    (a, b) => new Date(a.ingestion_date).getTime() - new Date(b.ingestion_date).getTime()
  );

  const filledItems = [];

  for (let i = 0; i < sortedItems.length - 1; i++) {
    filledItems.push(sortedItems[i]);

    const currentDate = new Date(sortedItems[i].ingestion_date);
    const nextDate = new Date(sortedItems[i + 1].ingestion_date);

    let nextDay = new Date(currentDate);
    nextDay.setDate(currentDate.getDate() + 1);

    while (nextDay < nextDate) {
      filledItems.push(null);
      nextDay.setDate(nextDay.getDate() + 1); // Move to the next day
    }
  }

  filledItems.push(sortedItems[sortedItems.length - 1]);
  return filledItems;
};

interface ChartDataProps {
  series: any[];
  title: string;
  xAxisTitle: string;
  yAxisTitle: string;
}

const ChartMeterDataIngestion: React.FC = () => {
  const [chartData, setChartData] = useState<ChartDataProps | null>(null);
  const items = useSelector(meterDataIngestionItemsSelector);
  const filledItems = useMemo(() => items && fillMissingDates(items), [items]);
  const categories = useMemo(() => filledItems?.map(item => item && dateFormat(item.ingestion_date)), [filledItems]);

  useEffect(() => {
    if (!filledItems) return;
    setChartData(null);
    setChartData({
      series: [
        {
          name: '',
          type: 'line' as any,
          lineWidth: 3,
          color: 'lightgray',
          data: filledItems.map(item => item && { y: Number(item.nr_files_ingested), color: '#ff0000' }),
        },
      ],
      title: '',
      xAxisTitle: '',
      yAxisTitle: 'Number of files',
    });
  }, [filledItems]);

  const options = useMemo(() => {
    const baseOptions = getBaseOptions((str: string) => str, chartData);
    return {
      ...baseOptions,
      xAxis: {
        ...baseOptions.xAxis,
        minTickInterval: 1,
        categories,
        labels: { enabled: false },
      },
      tooltip: {
        formatter(this: Highcharts.Point) {
          return `${this.key}<br><b>Number of files:</b> ${this.y}`;
        },
      },
      exporting: {
        enabled: false,
      },
      series: chartData?.series,
      legend: { enabled: false },
    };
  }, [chartData, categories]) as unknown as Highcharts.Options;

  return <Chart height="20vh" options={chartData ? options : null} />;
};

export default ChartMeterDataIngestion;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { usePaginationHandlers, useAutoFetch } from 'hooks';
import { startFerretAction } from 'modules/calculations';
import { setInfoToastAction, setModalConfirmationMetaAction } from 'modules/layouts';
import { DataTableTemplate, Button, Alert } from '@utiligize/shared/components';
import TableFerrets from './TableFerrets';
import { SelectEnvironment, SelectWorkflowTenant, ConfirmationModal } from 'components/_common';
import { FetchOptionsPageParam, PaginationType } from 'constants/index';
import { IconPlus } from '@utiligize/shared/resources';

const ViewFerrets: React.FC = () => {
  const dispatch: Shared.CustomDispatch = useDispatch();
  const type = PaginationType.ADMIN_FERRETS;
  const { filters, handleSelectChange } = usePaginationHandlers({ type });
  const { nextUpdateDiff, shouldFetch, resetCounter } = useAutoFetch({ fetchInterval: 20000 }); // 20 seconds

  const handleButtonClick = useCallback(() => {
    if (!filters?.tenant) {
      return dispatch(setInfoToastAction('Please select a tenant to start a new execution'));
    }
    dispatch(
      setModalConfirmationMetaAction({
        titleKey: 'Please confirm ferret execution',
        bodyComponent: (
          <Alert className="mb-0 text-center" variant="warning">
            You are about to start a ferret execution for tenant {filters?.tenant} on {filters?.environment}.
          </Alert>
        ),
        submitButtonProps: { labelKey: 'Continue', variant: 'primary' },
        onConfirm: () => dispatch(startFerretAction(filters?.environment, filters?.tenant)),
      })
    );
  }, [dispatch, filters?.environment, filters?.tenant]);

  return (
    <DataTableTemplate paddingBottom>
      <Row>
        <Col xs={2}>
          <SelectEnvironment
            name="environment"
            value={filters?.environment || null}
            onChange={handleSelectChange}
            mutedTextLabelKey="Environment"
          />
        </Col>
        <Col xs={2}>
          <SelectWorkflowTenant
            name="tenant"
            value={filters?.tenant}
            onChange={handleSelectChange}
            isClearable
            mutedTextLabelKey="Workflow tenant"
            environment={filters?.environment || null}
            isDisabled={!filters?.environment}
            page={FetchOptionsPageParam.FERRETS}
          />
        </Col>
        <Col>
          <Button icon={<IconPlus />} labelKey="Start a new execution" onClick={handleButtonClick} variant="primary" />
        </Col>
        <Col xs="auto" className="d-flex ml-auto">
          <small className="text-muted">
            Next update in{' '}
            <span style={{ width: '15px', display: 'inline-block', textAlign: 'center' }} className="text-bold">
              {nextUpdateDiff}
            </span>{' '}
            seconds
          </small>
        </Col>
      </Row>
      <>
        <TableFerrets shouldFetch={shouldFetch} resetCounter={resetCounter} />
        <ConfirmationModal />
      </>
    </DataTableTemplate>
  );
};

export default ViewFerrets;
